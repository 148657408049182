<template>
  <div class="page-content">
    <div class="mx-1">
    </div>
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-10 col-sm-10">
                <h4>Migration</h4>
              </div>
            </div>
          </div>
          <b-card-body v-if="step === 1" v-loading="loading.next">
            <b-card-title class="text-capitalize">Workspace</b-card-title>
            <b-card-sub-title class="mb-4">
              Choose workspace to get WABA ID and Phone Number.
            </b-card-sub-title>
            <b-row>
              <b-col>
                <el-select filterable :filter-method="handlerFilterWorkspace" @change="handlerGetChannel" class="w-100" placeholder="Select Workspace" v-model="form1.workspace_id">
                  <el-option v-for="(item) in workspace_list_filter" :value="item.id" :label="item.label" :key="item.id">{{ item.label }}</el-option>
                </el-select> &nbsp;
              </b-col>
            </b-row>
            <b-row v-loading="loading.channel">
              <b-col sm="6" md="6" class="mb-2">
                <div class="list-group">
                  <a class="list-group-item list-group-item-action">
                    <span class="font-weight-medium"><font-awesome size="xs" icon="phone"/>&nbsp; Whatsapp Bussiness ID</span>
                    <br/>{{ show.waba_id }}
                  </a>
                </div>
              </b-col>
              <b-col sm="6" md="6" class="mb-2">
                <div class="list-group">
                  <a class="list-group-item list-group-item-action">
                    <span class="font-weight-medium"><font-awesome size="xs" icon="phone"/>&nbsp; Phone Number</span>
                    <br/>{{ show.phone_number }}
                  </a>
                </div>
              </b-col>
            </b-row>
            <hr/>
            <b-card-title class="text-capitalize" :loading="loading.detail">New WABA ID : {{ show.new_waba_name }}</b-card-title>
            <b-card-sub-title class="mb-4">
              Give new Whatsapp Bussiness ID.
            </b-card-sub-title>
            <b-row>
              <b-col>
                <el-input
                  @change="handlerGetWaba"
                  type="text"
                  placeholder="Input new ID"
                  v-model="form1.new_waba_id"
                  maxlength="512"
                  show-word-limit
                />
              </b-col>
            </b-row>
            <hr/>
            <div class="d-flex float-right mb-4">
              <el-button @click="nextStep(step)" :disabled="!isValidStep1" size="small" type="primary">Continue</el-button>
            </div>
          </b-card-body>
          <b-card-body v-if="step === 2" v-loading="loading.submit">
            <b-card-title class="text-capitalize">Data Migration Details</b-card-title>
            <b-card-sub-title>
              Workspace : {{ show.workspace_name }}
            </b-card-sub-title>
            <b-row>
              <b-col sm="6" md="6" class="mb-2">
                <div class="list-group">
                  <a class="list-group-item list-group-item-action list-group-item-secondary">
                    <span class="font-weight-medium"><font-awesome size="xs" icon="phone"/>&nbsp; Current Whatsapp Bussiness</span>
                    <br/>ID : {{ show.waba_id }}
                    <br/>Name : {{ show.phone_number }}
                  </a>
                </div>
              </b-col>
              <b-col sm="6" md="6" class="mb-2">
                <div class="list-group">
                  <a class="list-group-item list-group-item-action list-group-item-success">
                    <span class="font-weight-medium"><font-awesome size="xs" icon="phone"/>&nbsp; New Whatsapp Bussiness</span>
                    <br/>ID : {{ form1.new_waba_id }}
                    <br/>Name : {{ show.new_waba_name }}
                  </a>
                </div>
              </b-col>
            </b-row>
            <hr/>
            <b-card-title class="text-capitalize">Input OPT Code</b-card-title>
            <b-card-sub-title class="mb-4">
              Give otp code to verified.
            </b-card-sub-title>
            <b-row>
              <b-col>
                <el-input
                  type="text"
                  placeholder="Input OTP"
                  v-model="form1.otp_code"
                  maxlength="512"
                  show-word-limit
                />
              </b-col>
            </b-row>
            <b-row v-if="!request_otp_at || this.timerCount < 1">
              <b-col>
                <div class="d-flex float-right mt-1 mb-2">
                  <b-link @click="nextStep(1)" v-if="isValidStep1">Request OTP Code</b-link>
                </div>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col>
                <div class="d-flex float-right mt-1 mb-2">
                  <b-card-title class="text-capitalize">00 : {{ this.timerCount }}</b-card-title>
                </div>
              </b-col>
            </b-row>
            <hr/>
            <div class="d-flex float-right mb-4">
              <el-button @click="submitOTP" :disabled="!isValidSubmit" size="small" type="primary">Submit</el-button>
              <el-button @click="backStep(step)" type="default" size="small">Back</el-button>
            </div>
          </b-card-body>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Moment from 'moment';
import countdown from 'countdown';
import _ from 'lodash';

import popupErrorMessages from '../../library/popup-error-messages';
import migrationAPI from '../../api/migration';

export default {
  name: 'migrationSPE',
  metaInfo: {
    title: 'Migration SPE',
  },
  beforeCreate() {
    this.isEmpty = _.isEmpty;
  },
  data() {
    return {
      workspace_list_filter: [],
      workspace_list: [],
      loading: {
        next: false,
        submit: false,
        channel: false,
        detail: false,
      },
      pin_enable: true,
      form1: {
        workspace_id: '',
        new_waba_id: '',
        otp_code: '',
        new_phone_number_id: '',
        // phone_number: '',
        // waba_id: '',
      },
      show: {
        workspace_name: '-',
        phone_number: '-',
        waba_id: '-',
        new_waba_name: '-',
      },
      step: 1,
      loaderStack: 0,
      loader: null,
      last_index: null,
      channel: 'whatsapp',
      request_otp_at: '',
      timerCount: 0,
    };
  },
  computed: {
    checkUser() {
      return this.$store.state.backOffice.userProfile;
    },
    checkPermission() {
      const users = this.$store.state.backOffice.userProfile;
      const path = this.$router.currentRoute.fullPath;
      const permission = users.permissions.find((v) => path.includes(v.path));
      return permission.configuration;
    },
    isValidStep1() {
      return this.pin_enable === false && this.form1.new_waba_id !== '' && this.show.waba_id !== '-' && this.show.phone_number !== '-' && this.show.new_waba_name !== '-';
    },
    isValidSubmit() {
      return this.pin_enable === false && this.form1.otp_code !== '' && this.form1.new_phone_number_id !== '' && this.form1.new_waba_id !== '' && this.show.waba_id !== '-' && this.show.phone_number !== '-' && this.show.new_waba_name !== '-';
    },
  },
  mounted() {
    this.getWorkspaces();
  },
  methods: {
    countDownTimer() {
      if (this.timerCount > 0) {
        setTimeout(() => {
          // this.timerCount -= 1;
          const getTime = countdown(Moment(this.request_otp_at).add(1, 'minutes'), Moment(), countdown.ALL);
          this.timerCount = getTime.seconds;
          this.countDownTimer();
        }, 1000);
      } else {
        this.request_otp_at = '';
      }
    },
    async getWorkspaces() {
      await migrationAPI.getWorkspaces()
        .then(async (res) => {
          this.workspace_list = res.data;
          this.workspace_list_filter = res.data;
        }).catch((err) => {
          console.log(err);
        });
    },
    handlerFilterWorkspace(keyword) {
      this.timeout = setTimeout(() => {
        if (keyword) {
          this.workspace_list_filter = this.workspace_list.filter((v) => v.label.toLowerCase().includes(keyword.toLowerCase()));
        } else {
          this.workspace_list_filter = this.workspace_list.map((v) => v);
        }
      }, 500);
    },
    async handlerGetChannel(key) {
      // this.showLoader();
      this.loading.channel = true;
      this.show.waba_id = '-';
      this.show.phone_number = '-';
      this.show.workspace_name = '-';
      this.pin_enable = true;
      await migrationAPI.getChannel(key)
        .then(async (res) => {
          await popupErrorMessages(res);
          if (!_.isEmpty(res.data)) {
            const phone = res.data.phone_number_data;
            this.show.workspace_name = res.data.workspace_name;
            this.show.phone_number = `${phone.display_phone_number} (${phone.verified_name})`;
            this.show.waba_id = res.data.waba_id;
            this.pin_enable = res.data.phone_number_data.is_pin_enabled;
          }
        }).catch(() => {});
      this.loading.channel = false;
      // this.hideLoader();
    },
    async handlerGetWaba(key) {
      this.showLoader();
      this.loading.detail = true;
      this.show.new_waba_name = '-';
      await migrationAPI.getDetailWaba(this.form1.workspace_id, key)
        .then(async (res) => {
          await popupErrorMessages(res);
          if (!_.isEmpty(res.data)) {
            this.show.new_waba_name = res.data.name;
          }
        }).catch(() => {});
      this.loading.detail = false;
      this.hideLoader();
    },
    async backStep(fromStep) {
      this.step = fromStep - 1;
    },
    async nextStep(fromStep) {
      this.request_otp_at = '';
      this.$confirm(this.$t('channels.confirm.request_code'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            this.loading.next = true;
            await migrationAPI.getOTP(this.form1)
              .then(async (res) => {
                await popupErrorMessages(res);
                if (!_.isEmpty(res.data)) {
                  this.step = fromStep + 1;
                  this.form1.new_phone_number_id = res.data.phone.id;
                  this.request_otp_at = res.data.request_otp_at;
                  // this.timerCount = countdown(Moment(this.request_otp_at).add(2, 'minutes'), Moment(), countdown.ALL);
                  const getTime = countdown(Moment(this.request_otp_at).add(1, 'minutes'), Moment(), countdown.ALL);
                  this.timerCount = getTime?.seconds;
                  this.loading.next = false;
                  this.countDownTimer();
                } else {
                  this.$message({
                    title: this.$t('failed'),
                    type: 'error',
                    message: this.$t('general.error.not_found'),
                    duration: 30 * 1000,
                    showClose: true,
                  });
                  this.form1.new_phone_number_id = '';
                  this.request_otp_at = '';
                  this.loading.next = false;
                }
              }).catch(() => {
                this.step = fromStep;
                this.loading.next = false;
              });
          }
          cb();
        },
      }).catch(() => {
        this.loading.next = false;
      });
    },
    async submitOTP() {
      this.$confirm(this.$t('channels.confirm.verify_code'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            this.showLoader();
            this.loading.submit = true;
            await migrationAPI.submitOTP(this.form1)
              .then(async (res) => {
                await popupErrorMessages(res);
                if (!_.isEmpty(res.data)) {
                  this.$message({
                    title: this.$t('general.success'),
                    type: 'success',
                    message: this.$t('general.waiting_download'),
                    duration: 30 * 1000,
                    showClose: true,
                  });
                  this.form1 = {
                    workspace_id: '',
                    new_waba_id: '',
                    otp_code: '',
                    new_phone_number_id: '',
                  };
                  this.show = {
                    workspace_name: '-',
                    phone_number: '-',
                    waba_id: '-',
                    new_waba_name: '-',
                  };
                  this.loading.submit = false;
                  this.hideLoader();
                  this.step = 1;
                }
              }).catch(() => {
                this.step = 2;
                this.loading.submit = false;
                this.hideLoader();
              });
          }
          cb();
        },
      }).catch(() => {
        this.loading.submit = false;
        this.hideLoader();
      });
    },
    moreText(string) {
      return string && string.length > 50 ? `${string.substr(0, 40)}...` : string;
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
  },
};
</script>
